const Subscription = [
  {
    title: 'Paquete 1',
    subTitle: 'Limites de $1.000 a $2.000',
    description: 'Limites de $1.000 a $2.000',
    limitUnder: '200',
    limitUpper: '4999',
    priceMonth: '50',
    priceAnnual: '100',
    default: true,
    custom: false,
    package: 'B50',
    packageAnnual: 'A100',
    features: [
      {
        title: 'Límites desde $1.000 a $4.999.',
      },
      {
        title: 'Comisión porcentual sobre tus referidos hasta del segundo nivel.',
      },
      {
        title: 'Comisión del 30% por ventas de licencia de primer nivel.',
      },
    ],
  },
  {
    title: 'Paquete 2',
    subTitle: 'Limites de $2.001 a $4.000',
    description: 'Limites de $2.001 a $4.000',
    limitUnder: '5000',
    limitUpper: '9999',
    priceMonth: '100',
    priceAnnual: '200',
    default: false,
    custom: false,
    package: 'B100',
    packageAnnual: 'A200',
    features: [
      {
        title: 'Límites desde $5.000 a $9.999.',
      },
      {
        title: 'Posibilidad de comisión porcentual sobre tus referidos hasta del segundo nivel.',
      },
      {
        title: 'Comisión del 30% por ventas de licencia de primer nivel.',
      },
      {
        title: 'Posibilidad de comisión por venta de licencia del 15% en segundo nivel.',
      },
    ],
  },
  {
    title: 'Paquete 3',
    subTitle: 'Límites de $4.001 hasta $10.000',
    description: 'Limites de $4.001 a $10.000',
    limitUnder: '10000',
    limitUpper: '500000',
    priceMonth: '150',
    priceAnnual: '300',
    label: 'Más popular',
    default: false,
    custom: false,
    package: 'B150',
    packageAnnual: 'A300',
    features: [
      {
        title: 'Límites desde $10.000 a $50.000.',
      },
      {
        title: 'Posibilidad de comisión porcentual sobre tus referidos en todos los niveles.',
      },
      {
        title: 'Comisión del 30% por ventas de licencia de primer nivel.',
      },
      {
        title:
          'Posibilidad de comisión por venta de licencia del 15% en segundo nivel y 5% en tercer nivel.',
      },
    ],
  },
]

export default Subscription
